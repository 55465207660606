import React from "react";
import { Text, Container } from "@atoms";
import { HubspotForm } from "@molecules";
import useDefaultsData from "../../craft/staticQueries/DefaultsQuery";

const EndofPageCta = ({ heading, descriptor, formId }) => {
  const { cta: defaults } = useDefaultsData();
  return (
    <section className="my-10 mb-20 sm:my-20">
      <Container padding className="relative text-center">
        <div className="relative flex flex-wrap items-center rounded-4xl bg-purple py-10 text-left text-white before:absolute before:inset-0 before:h-full before:w-full before:bg-text-short before:bg-cover before:opacity-20 sm:py-16 md:flex-nowrap md:rounded-br-6xl">
          <div className="w-full overflow-hidden md:w-2/3">
            <Container interior>
              <div className="mx-8 space-y-6 md:mx-0">
                <Text variant="h2" className="max-w-md">
                  {heading || defaults.heading}
                </Text>
                <Text variant="body" className="max-w-sm font-bold md:max-w-md">
                  {descriptor || defaults.descriptor}
                </Text>
              </div>
            </Container>
          </div>
          <div className="relative mx-auto -mb-20 mt-10 w-auto md:-my-20 md:w-1/3">
            <HubspotForm formId={formId || defaults.form_id} />
          </div>
        </div>
      </Container>
    </section>
  );
};

EndofPageCta.defaultProps = {};

export default EndofPageCta;
